import { Mermaid, IMermaidProps } from './Mermaid'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const BlockDiagram: React.FC<{
  roleName: string
  isLoading: boolean
  mermaidText: string
}> = (props: { roleName: string; isLoading: boolean; mermaidText: string }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const mermaidProps: IMermaidProps = {
    text: props.mermaidText,
  }

  return (
    <>
      {props.isLoading && (
        <div className='loading-container'>
          <Spin indicator={antIcon} className='loader-spin' />
        </div>
      )}
      {!props.isLoading && <Mermaid {...mermaidProps} />}
    </>
  )
}

export default BlockDiagram
