import React from 'react'
import './index.scss'
import { Input, Select, Tooltip, Spin } from 'antd'
import info from '../../assets/Description.svg'
import {
  prepareWorkshopText,
  RecommendedTopicText,
  configurationTooltip,
  agendaTooltip,
  industryToolTip,
} from './constants'
import { LoadingOutlined } from '@ant-design/icons'

interface ICreateRequestFormProps {
  handleChange: (value: string, key: string) => void
  handleDropdownChange: (value: string) => void
  industryTrends: string
  configurationsteps: string
  agenda: string
  promptValue: string
  roleName: string
  generatePrompt: () => void
  requestLoader: boolean
}

const { Option } = Select

const CreateRequestForm: React.FC<ICreateRequestFormProps> = (props: ICreateRequestFormProps) => {
  const topicList = [
    { label: 'Industry Trends', value: 'Industry Trends' },
    { label: 'Configuration Steps', value: 'Configuration Steps' },
    { label: 'Agenda', value: 'Agenda' },
  ]
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const mergedArrow = true
  return (
    <div className='create-request-section'>
      <div className='create-request-top-section'>
        <div className='prepareWorkhopText'>
          <img className='infoIconWorkshop' src={info} />
          {prepareWorkshopText}
        </div>
        <div className='workshop-name-section'>
          <label className='labelText'>Workshop Name</label>
          <Input
            className='inputField'
            value={props.roleName}
            placeholder='Enter Workshop Name'
            onChange={(e) => props.handleChange(e.target.value, 'roleName')}
          />
        </div>

        <div className='field-section'>
          <div className='recommendedTopicHeading'>Recommended Topics</div>
          <div className='recommendedTopicContent'>{RecommendedTopicText}</div>
        </div>
        <div className='field-section'>
          <label className='labelText'>Topic</label>
          <Select
            style={{ width: '100%' }}
            value={props.promptValue}
            placeholder='Select Topic'
            onChange={(e) => props.handleDropdownChange(e)}
          >
            {topicList.map((topic: { label: string; value: string }, index: number) => (
              <Option key={index} value={topic.value}>
                {topic.label}
              </Option>
            ))}
          </Select>
        </div>

        {props.promptValue === 'Industry Trends' && (
          <div className='field-section'>
            <div className='inputFieldSection'>
              <label className='labelText'>Industry Trends</label>
              <Tooltip placement='top' title={industryToolTip} arrow={mergedArrow}>
                <img className='infoIcon' src={info} />
              </Tooltip>
            </div>
            <Input
              className='inputField'
              value={props.industryTrends}
              onChange={(e) => props.handleChange(e.target.value, 'industryTrends')}
            />
          </div>
        )}
        {props.promptValue === 'Configuration Steps' && (
          <div className='field-section'>
            <div className='inputFieldSection'>
              <label className='labelText'>Configuration Steps</label>
              <Tooltip placement='top' title={configurationTooltip} arrow={mergedArrow}>
                <img className='infoIcon' src={info} />
              </Tooltip>
            </div>
            <Input
              className='inputField'
              value={props.configurationsteps}
              onChange={(e) => props.handleChange(e.target.value, 'configurationsteps')}
            />
          </div>
        )}
        {props.promptValue === 'Agenda' && (
          <div className='field-section'>
            <div className='inputFieldSection'>
              <label className='labelText'>Agenda</label>
              <Tooltip placement='top' title={agendaTooltip} arrow={mergedArrow}>
                <img className='infoIcon' src={info} />
              </Tooltip>
            </div>
            <Input
              className='inputField'
              value={props.agenda}
              onChange={(e) => props.handleChange(e.target.value, 'agenda')}
            />
          </div>
        )}
      </div>
      {props.promptValue && (
        <div className='create-request-bottom-section'>
          <button
            className='createRequestButton'
            style={
              !props.industryTrends && !props.configurationsteps && !props.agenda
                ? { opacity: '60%' }
                : { opacity: '1', cursor: 'pointer' }
            }
            onClick={props.generatePrompt}
          >
            {props?.requestLoader && <Spin indicator={antIcon} className='loader-spin' />}
            Create Request
          </button>
        </div>
      )}
    </div>
  )
}

export default CreateRequestForm
