import { useEffect, useState } from 'react'
import './App.scss'
import { TRPCProvider } from './Components/TRPCProvider'

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import UserAuthorization from './Components/AuthComponent'

const MainContent = () => {
  const [ascendProjectId, setAscendProjectId] = useState<string | null>(null)

  useEffect(() => {
    // Parse the current URL's search parameters
    const params = new URLSearchParams(window.location.search)

    // Get the value of the 'ascend_project_id' parameter
    let tempProjectId = params.get('ascend_project_id')
    if (tempProjectId === null) {
      tempProjectId = ''
    }

    setAscendProjectId(tempProjectId)

    // if (user_email && ascendProjectId !== '') {
    //   const fetchProjectNames = async () => {
    //     try {
    //       const names = await getProjectNames()
    //       setProjectNames(names)
    //     } catch (err) {
    //       console.error(err)
    //     }
    //   }
    //   fetchProjectNames()
    // }
    // Set the state with the obtained value
    // if (projectId && user_email) {
    //   const fetchProjectNames = async () => {
    //     try {
    //       const names = await getProjectNames()
    //       setAscendProjectId(names)
    //     } catch (err) {
    //       console.error(err)
    //     }
    //   }
    //   fetchProjectNames()
    // }
  }, [])

  return (
    <div className='App'>
      {ascendProjectId ? (
        <AuthenticatedTemplate>
          <TRPCProvider>
            <UserAuthorization />
          </TRPCProvider>
        </AuthenticatedTemplate>
      ) : (
        <>
          <UnauthenticatedTemplate />
          <UserAuthorization />
        </>
      )}
    </div>
  )
}

function App() {
  useMsalAuthentication(InteractionType.Redirect)
  return <MainContent />
}

export default App
