/* eslint-disable react/jsx-key */
import React, { Dispatch, SetStateAction } from 'react'
import { Modal, Input, Typography, Select, Button, Tooltip, Form, Spin } from 'antd'
import './index.scss'
import {
  addSnippetTooltipdisabled,
  addSnippetTooltipenabled,
  description,
  modalHeading,
  validationStatment,
} from './constants'
import { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select

type ISnippetProps = {
  parentList: { label: string; value: number }[]
  text?: string
  roleName?: string
  disabled?: boolean
  showModal: () => void
  currentActiveIndex:number
  handleModalOk: () => void
  setParentList: Dispatch<SetStateAction<{ label: string; value: number }[]>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  setInputValue: Dispatch<SetStateAction<{ name: string; parentId: number }>>
  setCurrent: Dispatch<SetStateAction<{ value: number }>>
  inputValue: { name: string; parentId: number }
  showModalLoader: boolean
  isModalOpen: boolean
  selectKey: number
  defaultLabel: string
  error: string
}

const SnippetModal = (props: ISnippetProps) => {
  const [form] = Form.useForm()
  const mergedArrow = {
    pointAtCenter: true,
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const handleCancel = () => {
    props.setParentList([{ label: 'Create new root', value: 0 }])
    props.setIsModalOpen(false) // close the modal
  }

  const handleChange = (value: string | number, type: string) => {
    props.setInputValue({ ...props.inputValue, [type]: value })
  }

  function validateForm() {
    return props.inputValue.name !== ''
  }

  return (
    <>
      <Tooltip
        placement='top'
        title={props?.disabled ? addSnippetTooltipdisabled : addSnippetTooltipenabled}
        arrow={mergedArrow}
      >
        <button
          className='square-button'
          style={
            props?.disabled
              ? { opacity: '60%', background: '#F4F4F499', cursor: 'not-allowed' }
              : { opacity: '1', background: '#F4F4F4', cursor: 'pointer' }
          }
          onClick={() => {
            props.setCurrent({ value: props.currentActiveIndex })
            props.showModal()
          }}
        >
          {props.showModalLoader ? <Spin indicator={antIcon} className='loader-spin' /> : <>+</>}
        </button>
      </Tooltip>
      <Modal
        className='modal_snippet'
        visible={props.isModalOpen}
        onOk={() => {
          props.handleModalOk()
        }}
        confirmLoading={false}
        onCancel={handleCancel}
        footer={[
          <Button key='cancel' onClick={handleCancel}>
            Cancel
          </Button>,
          <Tooltip placement='top' title={!validateForm() ? validationStatment : ''}>
            <Button
              className={validateForm() ? 'done-button' : ''}
              type='primary'
              onClick={() => {
                props.handleModalOk()
              }}
              disabled={!validateForm()}
            >
              {props.showModalLoader && <Spin indicator={antIcon} className='loader-spin' />}
              Done
            </Button>
          </Tooltip>,
        ]}
        okText='Done'
      >
        <h2>{modalHeading}</h2>
        <div
          className='bottomLine'
          style={{ borderBottom: '1px solid #E8E8E8', margin: '10px 0' }}
        />
        <p className='modal_description'>{description}</p>
        <Typography.Title level={5} className='input_label'>
          Snippet Name
        </Typography.Title>
        <Form form={form}>
          <Input
            placeholder='Enter Industry'
            value={props.inputValue.name}
            onChange={(e) => handleChange(e.target.value, 'name')}
            defaultValue=''
          />
          {props.error ? <span className='error'>{props.error}</span> : null}
        </Form>
        <Typography.Title level={5} className='input_label'>
          Parent
        </Typography.Title>
        <Select
          key={props.selectKey}
          style={{ width: '100%' }}
          placeholder='Select a parent'
          onChange={(e) => handleChange(e, 'parentId')}
          defaultValue={props.defaultLabel}
        >
          {props.parentList.map((list: { label: string; value: number }, index: number) => (
            <Option key={index} value={list.value}>
              {list.label}
            </Option>
          ))}
        </Select>
        <div
          className='bottomLine'
          style={{
            borderBottom: '1px solid #E8E8E8',
            marginTop: '25px',
            marginRight: '-24px',
            marginLeft: '-24px',
          }}
        />
      </Modal>
    </>
  )
}
export default SnippetModal
