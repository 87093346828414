import './index.scss'
import logo from '../../assets/Logo.svg'
import bg from '../../assets/background2.png'
import chatLogo from '../../assets/chat1.png'
import generateIcon from '../../assets/generateIcon.svg'
import projectStatusLoad from '../../assets/Glyphf.svg'
import projectStatusCompleted from '../../assets/projectNotice.svg'
import { useEffect, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { useMsal } from '@azure/msal-react'
import { Button } from 'antd'
import getToken from '../../addTokenInterceptor'

interface IHeaderComponent {
  projectNames: string
  openNotification: (placement: 'bottom') => void
  getPpt: () => any
  projectStatus: boolean
  showProjectStatus: boolean
  getOutputId: () => any
  addOutputFile: (doclink: string, docDownloadLink: string) => any
}

const HeaderTile: React.FC<IHeaderComponent> = (props: IHeaderComponent) => {
  const { instance } = useMsal()
  const { user_email, projectId } = getToken()
  const handleClick = () => {
    if (projectId !== null) {
      const commonPageUrl = new URL(import.meta.env.VITE_ASCEND_1_1_URL)
      commonPageUrl.searchParams.append('ascend_project_id', projectId)

      window.location.href = commonPageUrl.toString()
    }
  }

  const handleChatClick = () => {
    if (projectId !== null) {
      const searchAndChatUrl = new URL(import.meta.env.VITE_ASCEND_CHAT_URL)
      searchAndChatUrl.searchParams.append('ascend_project_id', projectId)
      window.location.href = searchAndChatUrl.toString()
    }
  }

  const handleEndSeessionClick = async () => {
    props.openNotification('bottom')
    const result = await props.getOutputId()
    const { doclink, docDownloadLink, pptlink, pptDownloadLink } = await props.getPpt()
    if (typeof doclink === 'string' && typeof pptlink === 'string') {
      await props.addOutputFile(doclink, docDownloadLink)
      await props.addOutputFile(pptlink, pptDownloadLink)
      const commonPageUrl = new URL(import.meta.env.VITE_ASCEND_1_1_URL)
      commonPageUrl.searchParams.append('ascend_project_id', projectId)
      window.location.href = commonPageUrl.toString()
    }
  }

  const logout = async () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    })
  }

  const [userEmail, setUserEmail] = useState('')
  const getUserEmail = async () => {
    setUserEmail(user_email)
  }

  const downloadDocumentation = (): string =>
    'https://amedeloitte.sharepoint.com/:w:/r/sites/GenerativeAI-Oracle/_layouts/15/download.aspx?SourceURL=Shared%20Documents/04.%20Training/Ascend%202.0/Ascend%20GenAI%20User%20Manual.pdf'
  useEffect(() => {
    getUserEmail()
  })

  return (
    <div style={{ width: '100%', zIndex: '999' }}>
      <div className='header'>
        <div className='header-left'>
          <img src={logo} alt='deloitte logo' className='header-logo' />
          <div className='vertical-line' />
          <span className='header-text'>
            Gen AI<sup>TM</sup>
          </span>
        </div>
        <div className='header-right'>
          <div className='float-right'>
            <div className='email-text'>
              <a href={downloadDocumentation()} className='content download-user-guide'>
                <DownloadOutlined className='download-icon' /> Download User Guide
              </a>
            </div>
            <div className='email-text'>
              <div className='content'>{userEmail}</div>
            </div>
            <Button className='sign-button' type='primary' onClick={() => logout()}>
              Sign Out
            </Button>
          </div>
        </div>
      </div>
      <div className='parent'>
        <img src={bg} className='image1' />
        <div className='generate-ppt-button' onClick={handleEndSeessionClick}>
          <img src={generateIcon} alt='' className='chat-icon generate-icon' />
          Generate PPT
        </div>
        <div className='chat-button' onClick={handleChatClick}>
          <img src={chatLogo} alt='' className='chat-icon' /> Search and Chat
        </div>
        <span className='back-arrow' onClick={handleClick}>
          &lt;
        </span>
        <span className='seperator'>|</span>
        <span className='workshop-heading'>
          {props.projectNames}
          {props.showProjectStatus ? (
            <span className='project-status'>
              <img src={props.projectStatus ? projectStatusCompleted : projectStatusLoad} />
              <span className='project-status-text'>
                {props.projectStatus ? `Completed` : `In progress`}
              </span>
            </span>
          ) : (
            <></>
          )}
        </span>
      </div>
    </div>
  )
}

export default HeaderTile
