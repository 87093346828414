/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import CreateRequestForm from '../../Components/create-request-form'
import { ConfigProvider, NotificationArgsProps, Tabs, TabsProps, notification } from 'antd'
import gotoToasterLoading from '../../assets/gotoToasterLoading.svg'
import Notice from '../../assets/Notice.svg'
import AssistantBotComponent from '../../Components/AssistantBotComponent'
import HeaderTile from '../../Components/header-tile'
import './index.scss'
import BlockDiagram from '../../Components/BlockDiagram'
import getToken from '../../addTokenInterceptor'
import moment from 'moment'
import {
  API_GET_OUTPUTID,
  API_GET_PROJECTNAME,
  API_POST_STREAM,
  API_POST_CREATE_PROMPT,
  API_GET_SNIPPET_RECORDS,
  API_POST_CREATE_SESSION,
  API_POST_CREATE_SNIPPET,
  API_POST_PPT,
  API_ADD_OUTPUTFILE,
} from '../../urls'
import { RecommendedTopicText } from '../../Components/create-request-form/constants'

interface IChatObj {
  role: string
  response: string
  time: string
  recommendedTopic: string
  topicValue: string
}

const Prepare: React.FC = () => {
  const [chat, setChat] = useState<IChatObj[]>([])
  const [roleName, setroleName] = useState('')
  const [industryTrends, setindustryTrends] = useState('')
  const [configurationsteps, setconfigurationsteps] = useState('')
  const [agenda, setagenda] = useState('')
  const [promptValue, setpromptValue] = useState('')
  const [question, setQuestion] = useState('')
  const [currentActiveTab, setCurrentActiveTab] = useState('1')
  const [loading, setLoader] = useState(false)
  const [requestLoader, setRequestLoader] = useState(false)
  const [inputValue, setInputValue] = useState({ name: '', parentId: 0 })
  const [showProjectStatus, setShowProjectStatus] = useState(false)
  const [projectStatus, setProjectStatus] = useState(false)
  const [parentList, setParentList] = useState<{ label: string; value: number }[]>([
    { label: 'Create new root', value: 0 },
  ])
  const [IsModalOpen, setIsModalOpen] = useState(false)
  const [DefaultLabel, setDefaultLabel] = useState('Create new root')
  const [SelectKey, setSelectKey] = useState(0)
  const [mermaidText, setMermaidText] = useState(``)
  const [isLoading, setIsLoading] = useState(false)
  const [recommendedTopic, setRecommendedTopic] = useState('')
  const [recommendedTopicValue, setRecommendedTopicValue] = useState('')
  const [lastQuestion, setLastQuestion] = useState('')
  const [showModalLoader, setShowModalLoader] = useState(false)
  const [projectNames, setProjectNames] = useState('')
  const { accessToken, idToken, projectId, user_email, user_name } = getToken()
  const [error, setError] = useState('')
  const [currentActiveIndex, setCurrentActiveIndex] = useState({ value: 0 })
  let Pid = 0
  let outputId = 0

  const getSnippetsRecords = async () => {
    setIsLoading(true)
    const getSession = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
    })
    if (!getSession.ok) {
      setIsLoading(false)
      throw new Error(`HTTP error! status: ${getSession.status}`)
    } else {
      setIsLoading(false)
      const getSessionResponse = await getSession.json()
      const idNameMapping: { [key: number]: string } = {}
      getSessionResponse[1].result.data.forEach(({ name, id }: { name: string; id: number }) => {
        idNameMapping[id] = name
      })
      let blockDiagramText = `graph TD\n`
      getSessionResponse[1].result.data.forEach(
        ({ id, parentId }: { parentId: number; id: number }) => {
          if (parentId != 0)
            blockDiagramText += `id${parentId}["${idNameMapping[parentId]}"] --> id${id}["${idNameMapping[id]}"]\n`
          else blockDiagramText += `id${id}["${idNameMapping[id]}"]\n`
        },
      )
      setMermaidText(blockDiagramText)
    }
  }
  const inputRole = { '1': roleName }
  const inputStr = JSON.stringify(inputRole)
  const urlEncodedInput = encodeURIComponent(inputStr)
  const url = `${API_GET_SNIPPET_RECORDS}${urlEncodedInput}`
  const getSessionSnippet = async () => {
    const getSession = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
    })
    if (!getSession?.ok) {
      const message = `An error has occurred: ${getSession.statusText}`
      throw new Error(message)
    } else {
      const getSessionResponse = await getSession.json()
      // eslint-disable-next-line prefer-destructuring
      const secondDictionary = getSessionResponse[1]
      return secondDictionary
    }
  }

  const createSession = async () => {
    const requestBody = {
      name: roleName,
      type: '',
      projectId: Number(projectId),
    }
    const response = await fetch(API_POST_CREATE_SESSION, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
      body: JSON.stringify(requestBody),
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
  }

  const getTopicInput = (promptValue: string) => {
    let topic = ''
    switch (promptValue) {
      case 'Industry Trends':
        topic = industryTrends
        break
      case 'Configuration Steps':
        topic = configurationsteps
        break
      case 'Agenda':
        topic = agenda
        break
      default:
        break
    }
    return topic
  }

  const createSnippet = async () => {
    const getBody = {
      name: inputValue.name,
      text: chat[currentActiveIndex.value]?.response,
      parentId: Pid,
      sessionName: roleName,
      projectId: Number(projectId),
      RecommendedTopic: chat[currentActiveIndex.value]?.topicValue,
      topicValue: chat[currentActiveIndex.value].recommendedTopic,
    }
    const getResponse = await fetch(API_POST_CREATE_SNIPPET, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
      body: JSON.stringify(getBody),
    })
    return getResponse
  }

  const getOutputId = async () => {
    const data = {
      projectId: Number(projectId),
      username: user_name,
      useremail: user_email,
    }
    const response = await fetch(API_GET_OUTPUTID, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
      body: JSON.stringify(data),
    })
    if (!response?.ok) {
      const message = `An error has occurred: ${response.statusText}`
      throw new Error(message)
    } else {
      const result = await response.json()
      outputId = result.result.data
      return outputId
    }
  }

  const addOutputFile = async (link: string, downloadLink: string) => {
    const data = {
      projectId: Number(projectId),
      outputId: Number(outputId),
      workshopname: roleName,
      fileurl: {
        link,
        downloadLink,
      },
      username: user_name,
    }
    const response = await fetch(API_ADD_OUTPUTFILE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        // 'Access-Token': accessToken,
        // 'project-id': projectId,
      },
      body: JSON.stringify(data),
    })
    if (!response?.ok) {
      const message = `An error has occurred: ${response.statusText}`
      throw new Error(message)
    } else {
      const result = await response.json()
      return result
    }
  }

  const getPpt = async () => {
    const data = {
      sessionName: roleName,
      projectId: Number(projectId),
      username: user_name,
      user_email: user_email,
      outputId: Number(outputId),
    }
    const placement = 'bottom'
    const response = await fetch(API_POST_PPT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
      body: JSON.stringify(data),
    })
    if (!response?.ok) {
      setShowProjectStatus(false)
      setProjectStatus(false)
      const message = `An error has occurred: ${response.statusText}`
      throw new Error(message)
    } else {
      api.info({
        message: `Project Technical Documentation workshop has been generated`,
        placement,
        duration: 3,
        icon: <img src={Notice} className='toaster-icon' />,
        className: 'notification',
      })
      setProjectStatus(true)
      setTimeout(() => {
        setShowProjectStatus(false)
        setProjectStatus(false)
      }, 3000)
      const result = await response.json()
      return result.result.data
    }
  }

  const getProjectNames = async () => {
    const data = {
      projectId: Number(projectId),
      userEmail: user_email,
    }
    const response = await fetch(API_GET_PROJECTNAME, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
      body: JSON.stringify(data),
    })
    if (!response?.ok) {
      const message = `An error has occurred: ${response.statusText}`
      throw new Error(message)
    } else {
      const result = await response.json()
      return result.result.data
    }
  }

  useEffect(() => {
    if (projectId && user_email) {
      const fetchProjectNames = async () => {
        try {
          const names = await getProjectNames()
          setProjectNames(names)
        } catch (err) {
          console.error(err)
        }
      }
      fetchProjectNames()
    }
  }, [projectId, user_email])

  const handleValidation = (snippetName: string) => {
    const pattern = /^[\w\s\-!@#.,$[\]\{\}()\\<>|/%^&*+=:;?~`]+$/
    if (snippetName.match(pattern)) {
      return true
    }
    return false
  }

  const handleChangeval = (value: string | number, type: string) => {
    setInputValue({ ...inputValue, [type]: value })
  }

  const showModal = async () => {
    if (roleName) {
      setShowModalLoader(true)
      const secondDictionary = await getSessionSnippet()
      if (!secondDictionary) {
        throw new Error(`HTTP error! status:`)
      } else {
        setShowModalLoader(false)
        let newOptions: { label: string; value: number }[] = secondDictionary.result.data.map(
          (item: { name: string; id: number }) => ({
            label: item.name,
            value: item.id,
          }),
        )
        newOptions = newOptions.sort((a, b) => a.value - b.value)
        const mergedOptions: { label: string; value: number }[] = [...parentList, ...newOptions]
        setParentList((list) => [...list, ...newOptions])
        const lastOption = mergedOptions[mergedOptions.length - 1]
        lastOption && setDefaultLabel(lastOption.label)
        handleChangeval(lastOption.label, 'parentId')
        setInputValue({ name: '', parentId: lastOption.value })
        setSelectKey((prevKey) => prevKey + 1)
      }
      if (!showModalLoader) {
        setError('')
        setIsModalOpen(true)
      }
    }
  }

  const handleModalOk = async () => {
    if (handleValidation(inputValue.name)) {
      const parentLabel = parentList.filter((item) => item.value === inputValue.parentId)[0]?.label

      try {
        const secondDictionary = await getSessionSnippet()
        if (!secondDictionary) {
          throw new Error(`Error in fetching session snippets`)
        } else {
          if (parentLabel === 'Create new root') {
            Pid = 0
          } else {
            const targetItem = secondDictionary.result.data.find(
              (item: { name: string | number }) => item.name === parentLabel,
            )
            Pid = targetItem.id
          }
          const dataLength = secondDictionary.result.data.length

          if (dataLength === 0) {
            await createSession()
          }

          const getResponse = await createSnippet()
          if (!getResponse.ok) {
            throw new Error(`HTTP error! status: ${getResponse.status}`)
          } else {
            setParentList([{ label: 'Create new root', value: 0 }])
          }
        }
      } catch {
        console.error('Failed handle ok')
      }
      setIsModalOpen(false) // close the modal
      setInputValue({ name: '', parentId: 0 })
    } else {
      setError('String should not contain quotes')
    }
  }

  const sendChat = () => {
    const tempChat = [...chat]
    tempChat.push({
      role: 'user',
      response: question,
      time: moment().format('hh:mm A'),
      recommendedTopic: recommendedTopic,
      topicValue: recommendedTopicValue,
    })
    setChat(tempChat)
    setLastQuestion(question)
    setQuestion('')
    setLoader(true)
    postChatMessage()
  }
  const sendRegenChatWithContext = () => {
    const tempChat = [...chat]
    tempChat.push({
      role: 'user',
      response:
        "Please regenerate the response table similar to the previous one. If the previous table included fields like 'Number', 'Trend', 'Description', and 'Example', ensure to include the same in the updated version, catering to the current market scenario. If the previous table had fields like 'Agenda Item' and 'Time', your response should also include these, with the agenda items reflecting our current tasks and the timings being suitable for all team members. Remember, the goal is to maintain the structure while refreshing the content to align with our current context",
      time: moment().format('hh:mm A'),
      recommendedTopic: recommendedTopic,
      topicValue: recommendedTopicValue,
    })
    setChat(tempChat)
    setQuestion('')
    setLoader(true)
    postRegenChatMessageWithContext()
  }
  const sendRegenChatWithoutContext = () => {
    const tempChat = [...chat]
    tempChat.push({
      role: 'user',
      response: 'Regenerate previous response',
      time: moment().format('hh:mm A'),
      recommendedTopic: recommendedTopic,
      topicValue: recommendedTopicValue,
    })
    setChat(tempChat)
    setQuestion('')
    setLoader(true)
    postRegenChatMessageWithoutContext()
  }
  const postRegenChatMessageWithContext = async () => {
    let loading = true
    // const { accessToken, idToken, projectId } = getToken()
    const requestChatList: { role: string; content: string }[] = []
    requestChatList.push({
      role: 'system',
      content:
        'You are a helpful assistant. Reply with short concise response. Do Not repeat yourself, nor repeat the question. Always reply with markdown. Use headings, lists and tables.',
    })
    for (let i = 0; i < chat.length; i++) {
      if (chat[i].role === 'user') {
        requestChatList.push({
          role: 'user',
          content: chat[i].response,
        })
      } else {
        requestChatList.push({
          role: 'assistant',
          content: chat[i].response,
        })
      }
    }
    requestChatList.push({
      role: 'user',
      content:
        "Please regenerate the response table similar to the previous one. If the previous table included fields like 'Number', 'Trend', 'Description', and 'Example', ensure to include the same in the updated version, catering to the current market scenario. If the previous table had fields like 'Agenda Item' and 'Time', your response should also include these, with the agenda items reflecting our current tasks and the timings being suitable for all team members. Remember, the goal is to maintain the structure while refreshing the content to align with our current context",
    })
    const response = await fetch(`/api/chat/regen`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
      body: JSON.stringify({
        chat: requestChatList,
      }),
    })

    if (response && response.body) {
      const reader = response.body.getReader()
      const decoder = new TextDecoder()
      let tempValue = ''
      while (loading) {
        const { value, done } = await reader.read()
        let decodedChunk = decoder.decode(value, { stream: true })
        // Replace '<br> -', '<br>-', '<br>' with '. '
        decodedChunk = decodedChunk.replace(/<br>\s*-?/g, '. ')

        if (value) {
          tempValue += decodedChunk
        }
        if (done) {
          loading = false
          setChat((prevChat) => [
            ...prevChat,
            {
              role: 'assistant',
              response: tempValue,
              time: moment().format('hh:mm A'),
              recommendedTopic: recommendedTopic,
              topicValue: recommendedTopicValue,
            },
          ])
          setLoader(false)
        }
      }
    }
  }
  const postRegenChatMessageWithoutContext = async () => {
    let loading = true
    const requestChatList = []
    requestChatList.push({
      role: 'system',
      content:
        'You are a helpful assistant. Reply with short concise response. Do Not repeat yourself, nor repeat the question. Always reply with markdown. Use headings, lists and tables.',
    })
    requestChatList.push({
      role: 'user',
      content: lastQuestion,
    })
    const response = await fetch(`/api/chat/regen`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
      body: JSON.stringify({
        chat: requestChatList,
      }),
    })

    if (response && response.body) {
      const reader = response.body.getReader()
      const decoder = new TextDecoder()
      let tempValue = ''
      while (loading) {
        const { value, done } = await reader.read()
        let decodedChunk = decoder.decode(value, { stream: true })
        // Replace '<br> -', '<br>-', '<br>' with '. '
        decodedChunk = decodedChunk.replace(/<br>\s*-?/g, '. ')

        if (value) {
          tempValue += decodedChunk
        }
        if (done) {
          loading = false
          setChat((prevChat) => [
            ...prevChat,
            {
              role: 'assistant',
              response: tempValue,
              time: moment().format('hh:mm A'),
              recommendedTopic: recommendedTopic,
              topicValue: recommendedTopicValue,
            },
          ])
          setLoader(false)
        }
      }
    }
  }
  const postChatMessage = async () => {
    let loading = true
    const requestChatList = []
    requestChatList.push({
      role: 'system',
      content:
        'You are a helpful assistant. Reply with short concise response. Do Not repeat yourself, nor repeat the question. Always reply with markdown. Use headings, lists and tables.',
    })
    for (let i = 0; i < chat.length; i++) {
      if (chat[i].role === 'user') {
        requestChatList.push({
          role: 'user',
          content: chat[i].response,
        })
      } else {
        requestChatList.push({
          role: 'assistant',
          content: chat[i].response,
        })
      }
    }
    requestChatList.push({
      role: 'user',
      content: question,
    })
    const response = await fetch(API_POST_STREAM, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
      body: JSON.stringify({
        chat: requestChatList,
      }),
    })

    if (response && response.body) {
      const reader = response.body.getReader()
      const decoder = new TextDecoder()
      let tempValue = ''
      while (loading) {
        const { value, done } = await reader.read()
        let decodedChunk = decoder.decode(value, { stream: true })
        // Replace '<br> -', '<br>-', '<br>' with '. '
        decodedChunk = decodedChunk.replace(/<br>\s*-?/g, '. ')

        if (value) {
          tempValue += decodedChunk
        }
        if (done) {
          loading = false
          setChat((prevChat) => [
            ...prevChat,
            {
              role: 'assistant',
              response: tempValue,
              time: moment().format('hh:mm A'),
              recommendedTopic: recommendedTopic,
              topicValue: recommendedTopicValue,
            },
          ])
          setLoader(false)
        }
      }
    }
  }

  const handleChange = (value: string, key: string) => {
    switch (key) {
      case 'roleName':
        setroleName(value)
        break
      case 'industryTrends':
        setindustryTrends(value)
        break
      case 'configurationsteps':
        setconfigurationsteps(value)
        break
      case 'agenda':
        setagenda(value)
        break
    }
  }

  const handleDropdownChange = (value: string) => {
    setpromptValue(value)
  }

  const makePrompApiCall = async (inputTopicValue: string, inputTopic: string) => {
    setRecommendedTopic(inputTopic)
    setRecommendedTopicValue(inputTopicValue)
    const response: Response = await fetch(API_POST_CREATE_PROMPT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-Id': projectId,
      },
      body: JSON.stringify({
        RecommendedTopics: inputTopicValue,
        inputValue: inputTopic,
        userEmail: user_email,
      }),
    })
    let loading = true
    if (response?.body) {
      const reader = response?.body.getReader()
      const decoder = new TextDecoder()
      let tempValue = ''
      while (loading) {
        const { value, done } = await reader.read()
        const decodedChunk = decoder.decode(value, { stream: true })
        if (value) {
          tempValue += decodedChunk
        }
        if (done) {
          loading = false
          setRequestLoader(false)
          // setLoadingQuestion(false)
          setQuestion(JSON.parse(tempValue)?.result?.data)
        }
      }
    }
  }
  //   useEffect(() => {
  //   if (roleName) getSnippetsRecords()
  // },[roleName])

  const generatePrompt = () => {
    setRequestLoader(true)
    switch (promptValue) {
      case 'Industry Trends':
        makePrompApiCall(promptValue, industryTrends)
        break
      case 'Configuration Steps':
        makePrompApiCall(promptValue, configurationsteps)
        break
      case 'Agenda':
        makePrompApiCall(promptValue, agenda)
        break
      default:
    }
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Workshop Preparation',
      children: (
        <div className='workshop-preparation-section'>
          <div className='create-request-section'>
            <CreateRequestForm
              handleChange={handleChange}
              handleDropdownChange={handleDropdownChange}
              generatePrompt={generatePrompt}
              requestLoader={requestLoader}
              industryTrends={industryTrends}
              configurationsteps={configurationsteps}
              agenda={agenda}
              promptValue={promptValue}
              roleName={roleName}
            />
          </div>
          <div className='assistant-bot-section'>
            <AssistantBotComponent
              question={question}
              setQuestion={setQuestion}
              roleName={roleName}
              chat={chat}
              loading={loading}
              sendChat={sendChat}
              sendRegenChatWithoutContext={sendRegenChatWithoutContext}
              sendRegenChatWithContext={sendRegenChatWithContext}
              currentActiveTab={currentActiveTab}
              handleModalOk={handleModalOk}
              showModal={showModal}
              setParentList={setParentList}
              parentList={parentList}
              setIsModalOpen={setIsModalOpen}
              setInputValue={setInputValue}
              inputValue={inputValue}
              showModalLoader={showModalLoader}
              isModalOpen={IsModalOpen}
              selectKey={SelectKey}
              defaultLabel={DefaultLabel}
              error={error}
              setCurrentActiveIndex={setCurrentActiveIndex}
            />
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Block Diagram',
      children: currentActiveTab === '2' && (
        <BlockDiagram roleName={roleName} mermaidText={mermaidText} isLoading={isLoading} />
      ),
    },
  ]

  const onChange = (key: string) => {
    setCurrentActiveTab(key)
    if (key === '2') {
      getSnippetsRecords()
    }
  }
  type NotificationPlacement = NotificationArgsProps['placement']
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (placement: NotificationPlacement) => {
    setShowProjectStatus(true)
    api.info({
      message: `Project Technical Documentation workshop is being generated`,
      placement,
      duration: 1,
      icon: <img src={gotoToasterLoading} className='toaster-icon' />,
      className: 'notification',
    })
  }

  return (
    <div style={{ height: 'inherit' }}>
      <HeaderTile
        projectNames={projectNames}
        getPpt={getPpt}
        openNotification={openNotification}
        projectStatus={projectStatus}
        showProjectStatus={showProjectStatus}
        getOutputId={getOutputId}
        addOutputFile={addOutputFile}
      />
      <>
        <div
          style={{ paddingLeft: '40px', overflow: 'hidden', height: 'calc(100% - 58px - 5.5rem)' }}
        >
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  inkBarColor: '#007CB0',
                  itemActiveColor: '#2E3238',
                  itemColor: '#59616C',
                  itemSelectedColor: '#2E3238',
                  itemHoverColor: '#2E3238',
                },
              },
            }}
          >
            <Tabs
              items={items}
              onChange={onChange}
              activeKey={currentActiveTab}
              tabBarStyle={{
                fontSize: '14px',
                color: '#2E3238',
                fontWeight: '600',
                font: 'Open Sans',
              }}
            />
          </ConfigProvider>
        </div>
      </>
      <div>{contextHolder}</div>
    </div>
  )
}

export default Prepare
